import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../App.css";
import simsIcon from "../assets/images/Auth/TechM.png";
import Dropdown from "react-bootstrap/Dropdown";

const TopHeader = (props) => {
const token = sessionStorage.getItem("token");
const details = sessionStorage.getItem("details");

const logout = () => {
    window.location = "/login";
};
useEffect(() => {
  }, token);

  return (
    <div>
      <nav className="main-header navbar-fixed-top navbar navbar-expand-md navbar-light navbar-white">
        <div
          className="container-fluid"
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          <NavLink exact to="/dashboard" className="navbar-brand">
            <img src={`${simsIcon}`} height="40" alt="SIMS Logo" />
            &nbsp;
            <span className="brand-text font-weight-bold roboto-bold">
              Component Tracking
            </span>
            &nbsp;&nbsp;
          </NavLink>
          <button
            className="navbar-toggler order-1"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse order-3" id="navbarCollapse">
            <ul className="navbar-nav navBar">
              {details == "ADMIN" && (
                <>
                  <li className="nav-item">
                    <NavLink exact to="/finalProd" className=" nav-link">
                      Finished Products
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink exact to="/supplierMaster" className="nav-link">
                      Suppliers
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink exact to="/componentMaster" className="nav-link">
                      Components
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink exact to="/inventory" className=" nav-link">
                      Inventory
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/servicehistoryadmin"
                      className=" nav-link"
                    >
                      Service History
                    </NavLink>
                  </li>
                </>
              )}
              {details == "SUPPLIER" && (
                <li className="nav-item">
                  <NavLink exact to="/inventory" className=" nav-link">
                    Supplier Inventory
                  </NavLink>
                </li>
              )}

              {details == ("INTERNAL" || "EXTERNAL") && (
                <li className="nav-item">
                  <NavLink exact to="/service" className="nav-link">
                    Service
                  </NavLink>
                </li>
              )}
            </ul>

          </div>

          <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            <li className="loggedas">Logged as: </li>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {details}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Profile</Dropdown.Item>
                <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default TopHeader;
